import './App.scss'
import logoImg from './images/logo.gif'

const App = () => {
  return (
    <div className="App">
      <img src={logoImg} alt="Logo" />
    </div>
  )
}

export default App
